import * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import {
  backendGetUserSession,
  localSetUserSession,
  userSessionGet,
  userSessionSet,
} from "../../service/user/UserService";
import DebugPage from "../page/debug/DebugPage";
import AuthenticatedRoute from "../../route/AuthenticatedRoute";
import LobbyPage from "../page/lobby/LobbyPage";
import ProfilePrimaryPage from "../page/profile/ProfilePrimaryPage";
import InboxSuperPane from "../super_pane/inbox/InboxSuperPane";
import InboxPage from "../page/inbox/InboxPage";
import DebugUserContextPage from "../page/debug/DebugUserContextPage";
import CalendarPage from "../page/calendar/CalendarPage";
import AsyncNetDebugPage from "../page/debug/AsyncNetDebugPage";
import ProfileAlternatePage from "../page/profile/ProfileAlternatePage";
import DebugMeetingContextPage from "../page/debug/DebugMeetingContextPage";
import CalendarMeetingInfoPlaceholderPane from "../pane/calendar/CalendarMeetingInfoPlaceholderPane";
import SettingsDisplayPage from "../page/settings/SettingsDisplayPage";
import AboutPage from "../page/about/AboutPage";
import {
  COOKIE_KEY_MAINTENANCE_MODE_AUTH,
  getCookie,
  setCookie,
} from "../../service/browser/CookieService";
import InputText from "../component/shared/input/InputText";
import ButtonRectangleLabel from "../component/shared/button/ButtonRectangleLabel";
import { Box, Typography } from "@mui/material";
import SettingsPrivacyPolicyPage from "../page/settings/SettingsPrivacyPolicyPage";
import HelpFaqPage from "../page/help/HelpFaqPage";
import HelpSupportAndFeedbackPage from "../page/help/HelpSupportAndFeedbackPage";
import CalendarMeetingInfoPage from "../page/calendar_meeting_info/CalendarMeetingInfoPage";
import HelpTutorialPage from "../page/help/HelpTutorialPage";

// Interactive Assets
// ....

// Custom Theme and Style
// ...

// StudiousCat Components

/*
FROM FIGMA:
/

/create-profile/privacy-policy
/create-profile/edit-profile
(ignore “done”)

/calendar
/settings/my-profile
/settings/display
/settings/privacy-policy
/settings/blocked
/inbox
/profile/<username>
/history
/help/faq
/help/support-and-feedback
/help/features-discussion
/about
*/

function MaintenanceAuthLogin({}) {
  const [inputPassword, setInputPassword] = useState("");
  const [authFail, setAuthFail] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);

  const checkPassword = () => {
    if (inputPassword === process.env.REACT_APP_MAINTENANCE_MODE_PASSWORD) {
      setCookie(
        COOKIE_KEY_MAINTENANCE_MODE_AUTH,
        process.env.REACT_APP_MAINTENANCE_MODE_PASSWORD
      );
      setAuthSuccess(true);
    } else {
      setAuthFail(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "20px",
        width: "100%",
        height: "100%",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          Welcome to StudiousCat!
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          Spring 2025 Setup
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 400,
          }}
        >
          The StudiousCat team is currently preparing for the Spring 2025 semester. Hang tight!
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 600,
          }}
        >
          If you are a developer performing maintenance on this site, please
          enter in your maintenance code below.
        </Typography>
      </Box>
      {authFail && (
        <Box>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 600,
              color: "red",
            }}
          >
            Auth Failed
          </Typography>
        </Box>
      )}
      {authSuccess && (
        <Box>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 600,
              color: "green",
            }}
          >
            Auth Success! Reload the Page!
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          width: "300px",
        }}
      >
        <InputText
          placeholderValue={"Password"}
          value={inputPassword}
          setValue={setInputPassword}
          required={true}
          maxCharCount={32}
        />
      </Box>
      <Box>
        <ButtonRectangleLabel label="Submit" onClick={() => checkPassword()} />
      </Box>
    </Box>
  );
}

function PlaceholderComponent({}) {
  return <div>placeholder</div>;
}

export default function Root() {
  const {
    authenticated,
    setAuthenticated,
    presentSessionUuid,
    setPresentSessionUuid,
    presentUserUuid,
    setPresentUserUuid,
  } = useContext(UserContext);

  const [routeReady, setRouteReady] = useState(false);

  const setupUserSession = async () => {
    userSessionGet()
      .then((sessionData) => {
        if (sessionData) {
          userSessionSet(
            sessionData,
            setAuthenticated,
            setPresentSessionUuid,
            setPresentUserUuid
          );
        }
        setRouteReady(true);
      })
      .catch((error) => {
        setRouteReady(true);
      });
  };

  useEffect(() => {
    setupUserSession();
  }, []);

  if (!routeReady) {
    return <div></div>;
  }

  if (process.env.REACT_APP_MAINTENANCE_MODE == "true") {
    const maintenanceAuth = getCookie(COOKIE_KEY_MAINTENANCE_MODE_AUTH);

    if (maintenanceAuth !== process.env.REACT_APP_MAINTENANCE_MODE_PASSWORD) {
      return <MaintenanceAuthLogin />;
    }
  }

  // return <InboxPage />

  const getDebugComponent = () => {
    // return <InboxPage />;
    // return <DebugTestNotificationPage />;
    // return <DebugUserContextPage />;
    // return <AsyncNetDebugPage />;
    return <DebugPage />;
    // return <LobbyPage />;
    // return <CalendarPage />
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LobbyPage />} />
        <Route
          path="/user/:userIdentifier"
          element={
            <AuthenticatedRoute>
              <ProfileAlternatePage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/calendar"
          element={
            <AuthenticatedRoute>
              <CalendarPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/meeting/:targetMeetingUuid"
          element={
            <AuthenticatedRoute>
              <CalendarMeetingInfoPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/settings/my-profile"
          element={
            <AuthenticatedRoute>
              <ProfilePrimaryPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/settings/display"
          element={
            <AuthenticatedRoute>
              <SettingsDisplayPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/settings/privacy-policy"
          element={
            <AuthenticatedRoute>
              <SettingsPrivacyPolicyPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/inbox"
          element={
            <AuthenticatedRoute>
              <InboxPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/help/faq"
          element={
            <AuthenticatedRoute>
              <HelpFaqPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/help/tutorial"
          element={
            <AuthenticatedRoute>
              <HelpTutorialPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/help/support-and-feedback"
          element={
            <AuthenticatedRoute>
              <HelpSupportAndFeedbackPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/about"
          element={
            <AuthenticatedRoute>
              <AboutPage />
            </AuthenticatedRoute>
          }
        />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
}
